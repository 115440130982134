import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogPost } from 'graphql-types';
import {
  BlogIndexPageTemplate,
  PAGINATION_PAGE_SIZE
} from '../../components/Blog/blog-index-page-template';

interface BlogPageProps {
  data: {
    posts: {
      edges: {
        node: SanityBlogPost;
      }[];
      totalCount: number;
    };
  };
}

const BlogPage = ({ data }: BlogPageProps) => {
  const posts = data?.posts.edges.map(edge => edge.node);
  const numOfPages = Math.ceil(data?.posts.totalCount / PAGINATION_PAGE_SIZE);

  return (
    <BlogIndexPageTemplate
      posts={posts}
      totalNumberOfPages={numOfPages}
      pathPrefix="blog"
      showHeadliner
    />
  );
};

export const query = graphql`
  fragment BlogPostCardInfo on SanityBlogPost {
    _id
    categories {
      title
    }
    id
    title
    slug {
      current
    }
    image {
      _type
      ...SanityImageFragment
    }
    title
    author {
      ...BlogPostAuthor
    }
    content: _rawContent
  }

  query BlogIndexQuery {
    posts: allSanityBlogPost(
      limit: 10
      filter: { slug: { current: { ne: null } } }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...BlogPostCardInfo
        }
      }
      totalCount
    }
  }
`;

export default BlogPage;
